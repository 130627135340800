@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .smooth-hover {
    @apply transform transition duration-300 ease-in-out;
  }

  .icon-xs {
    @apply h-3 w-3;
  }

  .icon-sm {
    @apply h-5 w-5;
  }

  .icon-md {
    @apply h-6 w-6;
  }

  .icon-lg {
    @apply h-8 w-8;
  }

  .icon-xl {
    @apply h-10 w-10;
  }

  .btn {
    @apply smooth-hover flex appearance-none items-center justify-center gap-1 rounded-lg border py-3 px-5 text-sm font-medium outline-none focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:opacity-80;
  }

  .btn-xs {
    @apply p-2 text-xs;
  }

  .btn-sm {
    @apply px-4 py-3 text-sm;
  }

  .btn-md {
    @apply px-6 py-3 text-base;
  }

  .btn-lg {
    @apply px-8 py-4 text-lg;
  }

  .btn-xl {
    @apply px-10 py-5 text-xl;
  }

  .btn-primary {
    @apply border-primary bg-primary capitalize text-white hover:bg-primary/80 focus:ring-primary/70;
  }

  .btn-outline-primary {
    @apply border-primary bg-transparent capitalize text-primary hover:bg-primary hover:text-white focus:ring-primary/70;
  }

  .btn-secondary {
    @apply border-secondary bg-secondary capitalize text-white hover:bg-secondary/80 focus:ring-secondary/70;
  }

  .btn-success {
    @apply border-success bg-success capitalize text-white hover:bg-success/80 focus:ring-success/70;
  }

  .btn-info {
    @apply border-info bg-info capitalize text-white hover:bg-info/80 focus:ring-info/70;
  }

  .btn-warning {
    @apply border-warning bg-warning capitalize text-white hover:bg-warning/80 focus:ring-warning/70;
  }

  .btn-danger {
    @apply border-danger bg-danger capitalize text-white hover:bg-danger/80 focus:ring-danger/70;
  }

  .form-control {
    @apply smooth-hover h-12 w-full appearance-none rounded-lg border border-gray-300 bg-light text-sm font-normal text-dark outline-none placeholder:text-gray-500 focus:border-primary focus:outline-none focus:ring-0;
  }
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
.back-img{
  background-image: url('../src/assets/images/micah-boswell-OPnBJ5L2oxs-unsplash.jpg');

}
@font-face {
  font-family: myfont;
  src: url(./fonts/Gilroy-Light.otf);
}

body{
  padding: 0;
  overflow: scroll;
  
  margin: 0;
}
body::-webkit-scrollbar {
  width: 0; 
  height: 0; /* Adjust this value to your preference */
}